"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
require("@/node_modules/angularjs-slider/dist/rzslider.js");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const imgCache = {};
function importAll(r) {
    r.keys().forEach((key) => (imgCache[key] = r(key).default));
}
//@ts-ignore
importAll(require.context('./assets', false, /\.(webp)$/));
//@ts-ignore
window.AudioContext = window.AudioContext || window.webkitAudioContext;
const defaultValues = {
    bpm: 120,
    beats: [0, 1, 2, 3],
    stressFirstBeat: true,
    subdivisions: '1',
    onlyOdd: false,
};
const subdivisionsPatterns = {
    '1': [1],
    '2': [1, 1],
    '3': [1, 1, 1],
    '4': [1, 1, 1, 1],
    '5': [1, 0, 0, 1],
    '6': [1, 1, 0, 0],
    '7': [1, 0, 1, 0, 1, 1],
    '8': [1, 1, 1, 0, 1, 0],
    '9': [1, 0, 1, 1, 1, 0],
    '10': [1, 0, 0, 1, 1, 1],
    '11': [1, 1, 1, 1, 0, 0],
};
class MetronomeCtrl {
    constructor($scope, $timeout, $filter) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$filter = $filter;
        this.audioContext = new AudioContext();
        this.bpmMin = 10;
        this.bpmMax = 250;
        this.imgCache = imgCache;
        this.commonSound = this.createSound(440, 0.7);
        this.accentSound = this.createSound(880, 0.9);
        this.subdivisionSound = this.createSound(520, 0.3);
        this.subdivisionsPatterns = ng.copy(subdivisionsPatterns);
        this.values = JSON.parse(localStorage.getItem(`${this.constructor.name}_values`) || 'null') || defaultValues;
        this.sliderOptions = {
            id: 'bpm',
            floor: this.bpmMin,
            ceil: this.bpmMax,
            step: 1,
            hideLimitLabels: true,
            hidePointerLabels: false,
            defaultVolume: this.values.bpm,
            onEnd: ((sliderId, modelValue, highValue, pointerType) => {
                this.game$.next();
            }).bind(this),
        };
        this.beatActive = undefined;
        this.pause = true;
        this.bpm$ = new rxjs_1.Subject();
        this.play$ = new rxjs_1.Subject();
        this.game$ = new rxjs_1.Subject();
        this.pause$ = new rxjs_1.BehaviorSubject(this.pause);
        this.beats$ = new rxjs_1.BehaviorSubject(this.values.beats.length);
        this.stop$ = new rxjs_1.Subject();
        this.titles = [
            {
                min: 0,
                max: 24,
                title: 'Larghissimo'
            },
            {
                min: 25,
                max: 39,
                title: 'Grave'
            },
            {
                min: 40,
                max: 59,
                title: 'Largo'
            },
            {
                min: 60,
                max: 65,
                title: 'Larghetto'
            },
            {
                min: 66,
                max: 75,
                title: 'Adagio'
            },
            {
                min: 76,
                max: 107,
                title: 'Andante'
            },
            {
                min: 108,
                max: 119,
                title: 'Moderato'
            },
            {
                min: 120,
                max: 167,
                title: 'Allegro'
            },
            {
                min: 168,
                max: 199,
                title: 'Presto'
            },
            {
                min: 200,
                max: 250,
                title: 'Prestissimo'
            },
        ];
        this.$scope.$watch('$ctrl.values', (values) => {
            localStorage.setItem(`${this.constructor.name}_values`, JSON.stringify(values));
        }, true);
    }
    $onInit() {
        this.play$.pipe((0, operators_1.switchMap)((ms) => {
            //@ts-ignore
            const subdivisions = this.subdivisionsPatterns[this.values.subdivisions] || [1];
            return (0, rxjs_1.interval)(ms / subdivisions.length).pipe((0, operators_1.startWith)(-1), (0, operators_1.withLatestFrom)(this.pause$.pipe((0, operators_1.tap)((a) => this.pause = a))), (0, operators_1.filter)(([v, paused]) => !paused), (0, operators_1.map)(([v, paused]) => v), (0, operators_1.tap)((v) => {
                const index = v + 1;
                if (v < 0) {
                    if ((!this.beatActive) && (this.values.stressFirstBeat)) {
                        this.playSound(this.accentSound);
                    }
                    else {
                        this.playSound(this.commonSound);
                    }
                }
                else if (subdivisions[index]) {
                    this.playSound(this.subdivisionSound);
                }
            }));
        })).subscribe();
        let i = -1;
        let numBar = 0;
        this.$scope.$watch('$ctrl.values.onlyOdd', (onlyOdd) => {
            if ((numBar % 2) == 0) {
                numBar += 1;
            }
        });
        this.game$.pipe((0, operators_1.switchMap)(() => {
            const int = 60 * 1000 / this.values.bpm;
            return (0, rxjs_1.interval)(int).pipe((0, operators_1.startWith)(i)).pipe((0, operators_1.withLatestFrom)(this.pause$.pipe((0, operators_1.tap)((a) => this.pause = a))), (0, operators_1.withLatestFrom)(this.beats$.pipe((0, operators_1.startWith)(this.values.beats.length), (0, operators_1.pairwise)(), (0, operators_1.tap)(([old, curr]) => {
                if (old != curr)
                    i = i % old;
            }))), (0, operators_1.filter)(([[v, paused], [oldBeatsLength, beatsLength]]) => !paused), (0, operators_1.map)(([[v, paused], [oldBeatsLength, beatsLength]]) => {
                return beatsLength;
            }), (0, operators_1.map)((beatsLength) => {
                i++;
                if (i && ((i % beatsLength) == 0)) {
                    numBar += 1;
                }
                return i % beatsLength;
            }), (0, operators_1.tap)((v) => {
                this.$scope.$evalAsync(() => {
                    this.beatActive = v;
                    if (this.values.onlyOdd) {
                        if ((numBar % 2) != 0) {
                            this.play$.next(int);
                        }
                    }
                    else {
                        this.play$.next(int);
                    }
                });
            }), (0, operators_1.takeUntil)(this.stop$));
        })).subscribe();
        this.bpm$.pipe((0, operators_1.pairwise)(), (0, operators_1.tap)((a) => {
            const bpm = Math.floor(60 * 1000 / (a[1] - a[0]));
            this.values.bpm = Math.min(Math.max(bpm, this.bpmMin), this.bpmMax);
            this.game$.next();
        })).subscribe();
    }
    getTitle(value) {
        for (let item of this.titles) {
            if ((item.min <= value) && (item.max >= value)) {
                return item.title;
            }
        }
        return undefined;
    }
    onClick(action) {
        if (action == 'start') {
            this.pause$.next(false);
            this.game$.next();
        }
        else if (action == 'pause') {
            this.pause$.next(true);
        }
    }
    setSubdivisions(key) {
        this.values.subdivisions = key;
    }
    add(name, step) {
        if (Array.isArray(this.values[name])) {
            let len = this.values[name].length;
            len += step;
            if (name == 'beats') {
                len = Math.min(Math.max(len, 1), 12);
                this.beats$.next(len);
            }
            if (len > 0)
                this.values[name] = [...Array(len).keys()];
            else
                this.values[name] = undefined;
        }
        else if (ng.isNumber(this.values[name])) {
            if (name == 'bpm') {
                this.values[name] = Math.min(Math.max(this.values[name] + step, 10), 250);
            }
        }
    }
    sub(name, step) {
        this.add(name, -1);
    }
    setBpm() {
        this.bpm$.next((new Date()).getTime());
    }
    debug() {
        this.playSound(this.commonSound);
        this.$timeout(() => {
            this.playSound(this.accentSound);
        }, 100);
        // this.playSound(this.commonSound)
        // this.playSound(this.accentSound)
        // this.playSound(this.subdivisionSound)
    }
    createSound(K, N) {
        var T = this.audioContext.sampleRate;
        var M = T * 0.1;
        var L = this.audioContext.createBuffer(1, M, T);
        var e = L.getChannelData(0);
        var S = 2 * Math.PI / T * K;
        var R = 100 / T;
        var P = 200 / T;
        var O = 500 / T;
        for (var Q = 0; Q < M; Q++) {
            e[Q] = N * (0.09 * Math.exp(-Q * R) * Math.sin(S * Q) + 0.34 * Math.exp(-Q * P) * Math.sin(2 * S * Q) + 0.57 * Math.exp(-Q * O) * Math.sin(6 * S * Q));
        }
        return L;
    }
    playSound(sound) {
        var note = this.audioContext.createBufferSource();
        note.buffer = sound;
        note.connect(this.audioContext.destination);
        var t = this.audioContext.currentTime;
        note.start(t);
        note.stop(t + 0.05);
    }
}
MetronomeCtrl.$inject = ['$scope', '$timeout', '$filter'];
const appModule = ng.module('app');
appModule.requires.push('rzSlider');
appModule.component('gameMetronome', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: MetronomeCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', 'ConfigServiceProvider', (WsServiceProvider, ConfigServiceProvider) => {
        WsServiceProvider.setPrefix('metronome/');
        ConfigServiceProvider.setDefaultConfig({
            cookie_show: '',
            dark_mode: 'no',
            sound_effects: false,
        });
    }]);
